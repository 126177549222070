import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import useTimeout from '~/hooks/useTimeout';

import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { PopoverProps } from '@mui/material';

import Dropdown from './Dropdown';

import type { ComponentProps } from 'react';
export type TooltipProps = {
  children: React.ReactElement<any, any> | React.ReactNode;
  content: React.ReactNode;
  tooltipClassName?: string;
  delay?: number | null;
  hideIcon?: boolean;
  popoverProps?: Partial<PopoverProps>;
  iconProps?: ComponentProps<typeof ExclamationCircleIcon>;
};

export default function Tooltip(props: TooltipProps) {
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { delay = 300 } = props;
  const enterTimer = useTimeout();
  const leaveTimer = useTimeout();

  const handleOpen = () => {
    setAnchorEl(anchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseOver = () => {
    enterTimer.clear();
    leaveTimer.clear();
    if (delay) {
      enterTimer.start(delay, () => {
        handleOpen();
      });
    } else {
      handleOpen();
    }
  };

  const handleMouseLeave = () => {
    enterTimer.clear();
    if (delay) {
      leaveTimer.start(delay, () => {
        handleClose();
      });
    } else {
      handleClose();
    }
  };

  if (!props.content) return props.children;

  const { className: iconClassName, ...otherIconProps } = props.iconProps || {};
  return (
    <div>
      <div
        ref={anchorRef}
        className="flex items-center"
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {props.children}
        {!props.hideIcon && (
          <ExclamationCircleIcon
            height={16}
            strokeWidth={2}
            className={twMerge(clsx('text-primary ml-1', iconClassName))}
            {...otherIconProps}
          />
        )}
      </div>
      <Dropdown
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: -6,
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        disableRestoreFocus
        disablePortal={false}
        onClose={handleClose}
        hideBackdrop
        {...props.popoverProps}
        slotProps={{
          ...props.popoverProps?.slotProps,
          root: {
            style: {
              position: 'unset',
            },
            ...props.popoverProps?.slotProps?.root,
          },
          paper: {
            className: '!rounded-xl z-[101] fixed',
            ...props.popoverProps?.slotProps?.paper,
          },
        }}
      >
        <div
          tabIndex={0}
          className={twMerge(
            clsx(
              'text-left p-2 shadow rounded-md font-normal tooltip bg-[--tooltip-color] text-[--tooltip-text-color] text-sm max-w-[400px]',
              props.tooltipClassName,
            ),
          )}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          {props.content}
        </div>
      </Dropdown>
    </div>
  );
}
