import clsx from 'clsx';
import { toast } from 'react-toastify';
import Dropdown from '~/components/Dropdown';
import Tooltip from '~/components/Tooltip';
import usePopover from '~/hooks/usePopover';
import { api } from '~/utils/api';
import { serverError } from '~/utils/serverError';

import { UserCircleIcon } from '@heroicons/react/24/outline';

import HeroIcon from './HeroIcon';

export default function UserIconButton() {
  const { mutate: changePassword } = api.user.changePassword.useMutation();
  const { open, handleClose, handleOpen, anchorEl } = usePopover<HTMLDivElement>();
  const { data: userData } = api.user.authorize.useQuery();

  return (
    <div className="">
      <Tooltip
        hideIcon
        content={
          <div>
            <div className="font-semibold">{userData?.user.email}</div>
            <div>{userData?.user.tenant.name}</div>
          </div>
        }
        popoverProps={{
          anchorOrigin: {
            vertical: 'center',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'center',
            horizontal: 'left',
          },
        }}
      >
        <div
          className={clsx(
            'flex cursor-pointer items-center space-x-2 p-3 rounded-3xl text-gray-400 font-semibold text-base no-underline hover:bg-primary hover:bg-opacity-10 border-solid border-2 border-transparent',
          )}
          tabIndex={0}
          role="button"
          onClick={handleOpen}
        >
          <HeroIcon Icon={UserCircleIcon} />
        </div>
      </Tooltip>
      <Dropdown open={open} anchorEl={anchorEl} onClose={handleClose}>
        <ul
          className="text-black z-[1] menu p-2 shadow bg-base-100 rounded-box w-[180px] font-semibold"
          tabIndex={0}
        >
          {' '}
          <li
            onClick={() =>
              changePassword(undefined, {
                onSuccess: (response) => {
                  toast.success(response);
                },
                onError: serverError,
              })
            }
          >
            <div>Change Password</div>
          </li>
          <li>
            {/* use <a> here so it can redirect to auth0 properly without cors issue */}
            <a href="/api/auth/logout">Logout</a>
          </li>
        </ul>
      </Dropdown>
    </div>
  );
}
