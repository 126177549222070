import Link from 'next/link';
import { usePathname } from 'next/navigation';

import {
  Cog6ToothIcon,
  CpuChipIcon,
  DocumentDuplicateIcon,
  DocumentMagnifyingGlassIcon,
  PuzzlePieceIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';

import logo from '../../../../public/logo.png';
import NavBarItem from './NavBarItem';
import UserIconButton from './UserIconButton';

export enum Routes {
  PROJECTS = '/projects',
  DECISION_STORE = '/decision-store',
  SETTINGS = '/settings',
  POLICY_ENGINE = '/policy-engine',
  CONTENT_SCANNER = '/content-scanner',
}

export default function NavBar() {
  const pathname = usePathname();

  return (
    <div className=" h-screen bg-[#0B0B0B] text-white flex flex-col p-4">
      <div className="flex justify-center">
        <Link href={'/'}>
          <img src={logo.src} alt="logo" width={32} />
        </Link>
      </div>
      <div className="flex-grow flex flex-col justify-center space-y-2">
        <NavBarItem
          label="Tasks"
          href={Routes.PROJECTS}
          icon={DocumentDuplicateIcon}
          isActive={pathname?.startsWith(Routes.PROJECTS)}
        />
        <NavBarItem
          label="Decision Store"
          href={Routes.DECISION_STORE}
          icon={PuzzlePieceIcon}
          isActive={pathname?.startsWith(Routes.DECISION_STORE)}
        />
        <NavBarItem
          label="Policy Engine"
          href={Routes.POLICY_ENGINE}
          icon={CpuChipIcon}
          isActive={pathname?.startsWith(Routes.POLICY_ENGINE)}
        />
        <NavBarItem
          label="Content Scanner"
          href={Routes.CONTENT_SCANNER}
          icon={DocumentMagnifyingGlassIcon}
          isActive={pathname?.startsWith(Routes.CONTENT_SCANNER)}
        />
      </div>
      <div className="flex flex-col space-y-2">
        <NavBarItem
          label="Help"
          href="https://protegeai.gitbook.io/docs/"
          newTab={true}
          icon={QuestionMarkCircleIcon}
        />
        <NavBarItem
          label="Settings"
          href={Routes.SETTINGS}
          icon={Cog6ToothIcon}
          isActive={pathname?.startsWith(Routes.SETTINGS)}
        />
        <UserIconButton />
      </div>
    </div>
  );
}
