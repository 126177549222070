import { useEffect, useRef } from 'react';

export class Timeout {
  static create() {
    return new Timeout();
  }

  currentId: ReturnType<typeof setTimeout> | null = null;

  /**
   * Executes `fn` after `delay`, clearing any previously scheduled call.
   */
  start<T extends () => void>(delay: number, fn: T) {
    this.clear();
    this.currentId = setTimeout(() => {
      this.currentId = null;
      fn();
    }, delay);
  }

  clear = () => {
    if (this.currentId !== null) {
      clearTimeout(this.currentId);
      this.currentId = null;
    }
  };
}

export default function useTimeout() {
  const timeoutRef = useRef(Timeout.create());

  useEffect(() => {
    timeoutRef.current.clear();
  }, []);

  return timeoutRef.current;
}
