/**
 * Generate background rgb color and initials from a given name for initials avatar
 * @param name name or title
 * @param characterLimit max number of character in initials
 * @returns rgb color and initials of the given name
 */
const getRandomColor = (name?: string | null, characterLimit = 3) => {
  const initials = name?.match(/\b\w/g)?.join('').slice(0, characterLimit).toUpperCase() || 'N/A';

  // get the ASCII code of the first character
  const asciiCode = initials.charAt(0).toLowerCase().charCodeAt(0);

  // number that contains 3 times ASCII value of character -- unique for every alphabet
  const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

  const num = Math.round(0xffffff * parseInt(colorNum));
  const r = (num >> 16) & 255;
  const g = (num >> 8) & 255;
  const b = num & 255;

  return {
    color: `rgb(${r}, ${g}, ${b}, 0.3)`,
    initials,
  };
};

export default function Initials({
  name,
  characterLimit = 3,
  className,
}: {
  name?: string | null;
  characterLimit?: number;
  className?: string;
}) {
  const { initials, color } = getRandomColor(name, characterLimit);

  return (
    <div className="avatar">
      <div
        className={[
          'w-12 rounded-full flex justify-center items-center font-semibold text-base',
          className,
        ].join(' ')}
        style={{ display: 'flex', backgroundColor: color }}
      >
        {initials}
      </div>
    </div>
  );
}
