import MainNavBar from './MainNavBar';
import ProjectBar from './ProjectBar';

export default function NavBar() {
  return (
    <div className="flex">
      <MainNavBar />
      <ProjectBar />
    </div>
  );
}
