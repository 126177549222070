import { TRPCClientErrorLike } from '@trpc/client';
import { toast } from 'react-toastify';

export const serverError = (e: TRPCClientErrorLike<any> | Error) => {
  console.error(e);
  toast.error(e.message);
};

/**
 * Returns readable message to be thrown from server for user
 * @param message
 * @returns
 */
export const transformErrorMessage = (message: string) => {
  return `There was an error in ${message}. Please share this error message or a screenshot with your support team over Slack or founders@protegecounsel.ai.`;
};

export class ServerError extends Error {
  public skipTransform = false;
  constructor(message: string, options?: { skipTransform?: boolean }) {
    super();
    this.message = message;
    this.skipTransform = !!options?.skipTransform;
  }
}
