import React from 'react';

type SpinnerProps = {
  isCentered?: boolean;
};

export default function Spinner({ isCentered }: SpinnerProps) {
  const spinner = <span className="loading loading-dots loading-lg text-primary"></span>;

  if (isCentered)
    return <div className="absolute w-full h-full flex items-center justify-center">{spinner}</div>;

  return spinner;
}
