import { ForwardRefExoticComponent, RefAttributes, SVGProps } from 'react';

export type TIcon = ForwardRefExoticComponent<
  Omit<SVGProps<SVGSVGElement>, 'ref'> & {
    title?: string | undefined;
    titleId?: string | undefined;
  } & RefAttributes<SVGSVGElement>
>;

type HeroIconProps = { Icon: TIcon };

export default function ({ Icon }: HeroIconProps) {
  return <Icon width={28} strokeWidth={2} />;
}
