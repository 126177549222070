'use client';

import { Popover, PopoverProps } from '@mui/material';

type DropdownProps = PopoverProps;

export default function Dropdown({ children, onClick, ...others }: DropdownProps) {
  return (
    <Popover
      disablePortal
      {...others}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        ...others.anchorOrigin,
      }}
      slotProps={{
        ...others.slotProps,
        paper: { className: '!rounded-xl', ...others.slotProps?.paper },
      }}
      onClick={(e) => {
        // e.stopPropagation();
        onClick?.(e);
      }}
    >
      {children}
    </Popover>
  );
}
