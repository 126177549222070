import clsx from 'clsx';
import Link from 'next/link';

import HeroIcon, { TIcon } from './HeroIcon';

type NavBarItemProps = {
  label: string;
  isActive?: boolean;
  icon: TIcon;
  href: string;
  newTab?: boolean;
};

export default function NavBarItem(props: NavBarItemProps) {
  return (
    <Link
      href={props.href}
      target={props.newTab ? '_blank' : ''}
      className={clsx(
        'flex items-center justify-center p-3 rounded-full border-none no-underline hover:bg-primary hover:bg-opacity-10 tooltip tooltip-right',
        props.isActive ? 'text-primary' : 'text-gray-400',
      )}
      data-tip={props.label}
    >
      <HeroIcon Icon={props.icon} />
    </Link>
  );
}
