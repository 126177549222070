import { useState } from 'react';

export default function usePopover<T = HTMLElement>() {
  const [anchorEl, setAnchorEl] = useState<T | null>(null);

  const handleOpen = (event: React.MouseEvent<T>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return { id, open, handleOpen, handleClose, anchorEl, setAnchorEl };
}
