'use client';

import { redirect, RedirectType } from 'next/navigation';
import React from 'react';
import NavBar from '~/components/NavBar';
import { api } from '~/utils/api';

import { useUser } from '@auth0/nextjs-auth0/client';
import Spinner from '~/components/Spinner';
import { serverError } from '~/utils/serverError';

function RootLayout({ children }: { children: React.ReactNode }) {
  const { user, isLoading: isAuth0Loading, error: auth0Error } = useUser();
  const { error: authorizeError, isLoading: isAuthorizeLoading } = api.user.authorize.useQuery(
    undefined,
    {
      enabled: !!user,
      refetchOnWindowFocus: false,
      onError: serverError,
    },
  );

  const isLoading = isAuth0Loading || isAuthorizeLoading;
  const error = auth0Error || authorizeError;

  // TODO: better error handling
  if ((!isAuth0Loading && !user) || error) return redirect('/api/auth/login', RedirectType.replace);

  return (
    <html lang="en">
      <body>
        {isLoading ? (
          <Spinner isCentered />
        ) : (
          <div className="flex">
            <NavBar />
            <div className="w-full h-screen overflow-y-auto relative">{children}</div>
          </div>
        )}
      </body>
    </html>
  );
}

export default api.withTRPC(RootLayout);
